import styled from 'styled-components';

export const MainContentContainer = styled.div`
  margin-top: -80px;

  .download-detail-main--content-container {
    .download-detail-main--wrapper {
      display: flex;
      padding: 0 28px;
      max-width: 1252px;
      margin: 0 auto 80px;

      .download-detail-main--content {
        flex: 6;
        overflow: auto;
        padding-right: 40px;
      }

      .download-detail-main--sidebar {
        flex: 4;
        min-width: 0;
        max-width: 475px;

        .download-detail-main--sidebar-content {
          &.sticky-bar {
            position: sticky;
            top: 140px;
          }

          .sidebar-detailed-demo {
            margin-top: 25px;
          }
        }

        .download-detail-main--side-form {
          .dynamic-form--container {
            padding: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .download-detail-main--content-container {
      .download-detail-main--wrapper {
        padding: 0 20px;
      }
    }
  }

  @media (max-width: 575px) {
    .download-detail-main--content-container {
      .download-detail-main--wrapper {
        flex-direction: column-reverse;
        margin-bottom: 40px;

        .download-detail-main--content {
          padding-right: 0;
          padding-top: 40px;
        }

        .download-detail-main--sidebar {
          max-width: 100%;
        }
      }
    }
  }

  .download-detail-main--banner-ad-container {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DownloadBannerAdContainer = styled.div`
  & > div {
    margin: 0 auto !important;

    ${(props) =>
      props.type === 'ContentfulConversionPanel' &&
      `
      max-width: 100% !important;
      padding: 0 !important;

      & > div {
        border-radius: 0;
      }
    `}
  }
`;

export const OnSuccessDownloadAgainCta = styled.div`
  margin-top: 30px;

  .dynamic-form--container {
    margin: 0 !important;
    background: var(--white);
    border: 1px solid var(--grey-3);
    box-sizing: border-box;
    box-shadow: 0px 15px 40px rgb(11 26 33 / 10%);
    border-radius: 8px;
    padding: 20px;
  }
  .signup-form--title {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-25);
    margin-bottom: 0px;
  }
  .signup-form-button--container {
    display: block;
    padding: 0;
  }
  .slick-list {
    height: max-content !important;
  }
  .signup-form--submit-button {
    font-size: var(--font-size-16);
    height: 48px;
    width: 100%;
    background-color: var(--titan-blue-3);
    margin: 0 !important;

    &:hover {
      background-color: var(--titan-blue-4);
    }
  }
`;
