import React from 'react';
import categoryIcon from 'images/downloads-category-icon.svg';

import { HeroContainer } from './styles';

const DownloadDetailHero = ({ backgroundImg, downloadableContentType, title, industry, state }) => {
  return (
    <HeroContainer backgroundImg={backgroundImg}>
      <div className="download-detail-hero--wrapper">
        <p className="download-detail-hero--sub-title font-overline">{`${downloadableContentType} Guides`}</p>
        <h1 className="download-detail-hero--title font-h2">{title}</h1>
        <p className="download-detail-hero--category">
          <img
            className="download-detail-hero--category-icon"
            src={categoryIcon}
            alt="category-icon"
          />
          {`${industry ? `${industry.join(', ')}${state ? ', ' : ''}` : ''}${state || ''}`}
        </p>
      </div>
    </HeroContainer>
  );
};

export default DownloadDetailHero;
