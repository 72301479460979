import React, { useCallback, useState, useEffect } from 'react';
import { isInternalLink } from 'utils/urlHelper';
import WebsiteImage from 'components/WebsiteImage';
import MarketoForm from 'components/Form/MarketoForm';
import StyledRichText from 'components/RichText/StyledRichText';
import BannerAd from 'components/Page/Toolbox/BannerAd';
import { WithUserContext } from 'utils/UserContext';
import ReactResizeDetector from 'react-resize-detector';
import LoggedInUserDownload from 'components/Page/Toolbox/LoggedInUserDownload';
import GetDemo from 'components/CallToActions/GetDemo';
import snackbar from 'utils/snackbar';

import {
  MainContentContainer,
  DownloadBannerAdContainer,
  OnSuccessDownloadAgainCta,
} from './styles';

const DownloadDetailMain = ({ data, userInfo }) => {
  const {
    downloadableContentType,
    featuredImage,
    title,
    content,
    inlineMarketingForm: marketoForm,
    components,
  } = data;

  const [displayOnSuccessDemo, setDisplayOnSuccessDemo] = useState(false);
  const [isSticky, setSticky] = useState(true);
  const [heights, setHeights] = useState({
    windowHeight: 900,
    sidebarHeight: 0,
  });

  // Memoized handleResize function
  const handleResize = useCallback(() => {
    setHeights((prevHeights) => ({
      ...prevHeights,
      windowHeight: window?.innerHeight,
    }));
  }, []);

  useEffect(() => {
    // Add event listeners
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleSidebarHeight = (width, height) => {
    setHeights((prevHeights) => ({
      ...prevHeights,
      sidebarHeight: height,
    }));
  };

  useEffect(() => {
    const { windowHeight, sidebarHeight } = heights;
    setSticky(sidebarHeight < windowHeight - 150);
  }, [heights]);

  const formSubmitSuccess = () => {
    const { redirectUrl } = marketoForm;
    const isInternalRedirect = isInternalLink(redirectUrl);

    if (typeof window !== 'undefined') {
      window.open(redirectUrl, redirectUrl.match(/.+\.pdf$/i) ? '_blank' : '_self');
    }

    setTimeout(() => {
      setDisplayOnSuccessDemo(true);
    }, 1000);

    if (redirectUrl.match(/.+\.pdf$/i)) {
      snackbar(
        isInternalRedirect
          ? 'Download started, item also sent to provided email'
          : 'Download started',
      );
    }
  };

  return (
    <MainContentContainer displayOnSuccessDemo={displayOnSuccessDemo}>
      <div className="download-detail-main--content-container">
        <div className="download-detail-main--wrapper">
          <div className="download-detail-main--content">
            {featuredImage?.image && (
              <WebsiteImage
                image={featuredImage?.image}
                alt={title}
                maxWidth="100%"
                style={{ marginBottom: 40 }}
              />
            )}

            {content && <StyledRichText content={content} />}
          </div>
          <div className="download-detail-main--sidebar">
            {displayOnSuccessDemo && (
              <>
                <GetDemo showDetailedGetDemo />
                {!userInfo && (
                  <OnSuccessDownloadAgainCta>
                    <MarketoForm
                      buttonLabel={`${downloadableContentType} Guide`}
                      inlineMarketingForm={marketoForm}
                      shouldRedirect={false}
                      {...(marketoForm.redirectUrl && { onSuccess: formSubmitSuccess })}
                      title="Download again"
                      allowMultiSubmissions
                    />
                  </OnSuccessDownloadAgainCta>
                )}
              </>
            )}
            {!userInfo && marketoForm && !displayOnSuccessDemo && (
              <ReactResizeDetector handleHeight onResize={handleSidebarHeight}>
                <div
                  className={`download-detail-main--sidebar-content ${
                    isSticky ? 'sticky-bar' : ''
                  }`}
                >
                  <MarketoForm
                    inlineMarketingForm={marketoForm}
                    title={marketoForm.formTitle || 'Get Now!'}
                    shouldRedirect={false}
                    {...(marketoForm.redirectUrl && { onSuccess: formSubmitSuccess })}
                    submittedCtaLabel="View Download"
                  />
                  <GetDemo />
                </div>
              </ReactResizeDetector>
            )}
            {userInfo && marketoForm && (
              <div className="download-detail-main--sidebar-content">
                <LoggedInUserDownload
                  inlineMarketingForm={marketoForm}
                  title={marketoForm.formTitle}
                  buttonTitle="Download Now"
                />
                <GetDemo />
              </div>
            )}
          </div>
        </div>
      </div>

      {components?.map((ele, index) => (
        <DownloadBannerAdContainer
          className="download-detail-main--banner-ad-container"
          type={ele.__typename}
          key={index}
        >
          <BannerAd bannerContent={ele} />
        </DownloadBannerAdContainer>
      ))}
    </MainContentContainer>
  );
};

export default WithUserContext(DownloadDetailMain);
